<template>
  <div class="accountInfo">
    <!-- 顶部导航 -->
    <commonNavBar :title="'账号信息'"></commonNavBar>
    <div
      class="infoBox"
      v-watermark
    >
      <van-cell-group>
        <van-cell
          title="账号"
          :value="$store.state.user.profile.loginName || '--'"
        />
        <van-cell
          title="密码"
          :value="'修改'"
          is-link
          to="/changePassword"
        />
      </van-cell-group>
    </div>
    <van-button
      type="info"
      @click="logoutHandler"
    >退出账号</van-button>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { resetRouter } from '@/router/index'
export default {
  name: 'accountInfo',
  data() {
    return {

    }
  },
  methods: {
    ...mapMutations('user', ['removeTokenInfo', 'removeUserProfile']),
    ...mapActions('message', ['destroyConnection']),
    // 退出登录
    logoutHandler() {
      this.removeTokenInfo()
      this.removeUserProfile()
      resetRouter()
      this.$router.replace('/loginPage')
      this.$toast.success({
        message: '退出登录!',
        duration: 500
      })
      this.destroyConnection()
    }
  }
}
</script>

<style lang="scss" scoped>
.accountInfo {
  ::v-deep {
    .infoBox {
      height: calc(100vh - 46px);
      position: fixed;
      width: 100%;
      overflow: auto;
    }

    .van-button--info {
      position: absolute;
      bottom: 40px;
      width: 90%;
      left: 50%;
      transform: translateX(-50%);
      background-color: #00a6f0;
      height: 35px;
    }
  }
}
</style>
